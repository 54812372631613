
.container1
{
  width: 100%;
  margin: 0px auto;

}


.menu
{
  margin-top: 10px;
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: .75em;
  padding-right: .75em;
  }
  .card-registration .select-arrow {
  top: 13px;
  }

  .fontFamily
  {
    font-family: "Georgia", Times, serif;
  }

  .bgColorDemo
  {
    background:rgba(0, 0, 0, 0.2);
  }

  .text-justify {
    text-align: justify;
}

.responsive-map
{
  overflow: hidden;
  height:0;
  padding-bottom: 30%;
  position: relative;
}

.responsive-map iframe
{
  border: 0;
  height:100%;
  width: 100%;
  left:0;
  top:0;
  position: absolute;
}